// ----------------------------------------------------------------------

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  doctorphotoURL: '/ProfilePicture/Doctor.png',
  patientphotoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

export default account;
