import { Navigate, useRoutes } from 'react-router-dom';
// layouts
//
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import DoctorLogin from "./components/Doctor/DoctorLogin";
import Patient from "./components/Patient/Patient";
import VideoPlay from "./components/VideoPlay";
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import DoctorForgotPassword from './components/Doctor/DoctorForgotPassword';
import DoctorResetPassword from './components/Doctor/DoctorResetPassword';
import AccountManagement from './pages/AccountManagement';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/',
      element: <Login />,
    },
    {
      path: 'doctor/signin',
      element: <DoctorLogin />,
    },
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: 'patient/id/:id',
      element: <Patient />,
    },
    {
      path: 'video/id/:id',
      element: <VideoPlay />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
    },
    {
      path: 'doctor/forgot-password',
      element: <DoctorForgotPassword />,
    },
    {
      path: 'doctor/reset-password',
      element: <DoctorResetPassword />,
    },
    {
      path: '/account-management',
      element: <AccountManagement />,
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
