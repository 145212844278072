import {React, useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, TextField, Alert, Container, InputAdornment, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// hooks
// components
import Page from '../Page';
// sections
import { useForm } from 'react-hook-form';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const DoctorForgotPassword = () => {
  const navigation = useNavigate();
  useEffect(()=>{
    var Token = localStorage.getItem("token");
    if(Token){
      navigation("/dashboard")
    }
  },[])
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const [isValid, setIsValid] = useState(false);
      const [linkSent, setLinkSent] = useState(false);
      const [formData, setFormData] = useState();

      const handleResetPassword = async(data) => {
			setFormData(data);
        await fetch(process.env.REACT_APP_NODE_API_URL+'/api/doctorforgotPassword', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((data) => {
              if (!data.message) {
                reset(formData);
                setLinkSent(true);
                setIsValid(false);
              } else {
                setLinkSent(false);
                setIsValid(true);
              }
              return data;
            });
        
      };
      const handleError = (errors) => {};
      const forgotOption = {
        email: { required: 'This field is required' },
      };

    return (
        <div className='title-content'>
      <Page title="Forgot Password">
        <RootStyle>
          <Container className='page-box' maxWidth="sm">
            <ContentStyle>
            <form onSubmit={handleSubmit(handleResetPassword, handleError)}>
      <div className='form-section' >
      {isValid ? (
        <Alert className='page-alert' severity="error">
          Invalid Email Address
        </Alert>
      ) : (
        ''
      )}
       {linkSent ? (
        // <Alert severity="success">
        //   Reset Link Sent
        // </Alert>
        <p>We sent reset password link to change your password in your registered email, please check your inbox.</p>
      ) : (
        <Stack spacing={3}>
        <TextField type="text" fullWidth name="email" label="Email" {...register('email', forgotOption.email)} />
        <small className="text-danger">{errors?.email && errors.email.message}</small>
        <LoadingButton className='page-button' fullWidth size="large" type="submit" variant="contained">
          Reset
        </LoadingButton>
        <Link className="nav-link" to="/doctor/signin">Click here to Signin</Link>
      </Stack>
      )}
      </div>
    </form>

            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>

      </div>
    );
};

export default DoctorForgotPassword;