import { React, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
// @mui
import { Icon } from "@iconify/react";

import {
  Stack,
  TextField,
  Alert,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import jwt_decode from "jwt-decode";
import OtpVerification from "./OtpVerification";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [token, setToken] = useState("");
  const [isOtp, setIsOtp] = useState(false);
  const [hash, setHash] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [preference, setPreference] = useState("");

  useEffect(() => {
    var Token = localStorage.getItem("token");
    if (Token) {
      navigate("/dashboard");
    }
  }, []);

  const handleLogin = async (data) => {
    await fetch(process.env.REACT_APP_NODE_API_URL + "/api/Login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.message) {
          if (data.otp) {
            var decoded = jwt_decode(data.Token);
            setId(decoded.CreateUserToken.id);
            setToken(data.Token);
            setHash(data.hash);
            setPhone(data.authKey);
            setPreference(data.preference);
            setIsOtp(true);
          } else {
            localStorage.setItem("token", data.Token);
            var decoded = jwt_decode(data.Token);
            setId(decoded.CreateUserToken.id);
            if (decoded.CreateUserToken.role_id == 3) {
              navigate("/dashboard");
            }
          }
        } else {
          setIsValid(true);
        }
        return data;
      });
  };
  const handleError = (errors) => {};

  const loginOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Please enter a valid email",
      },
    },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
  };

  return (
    <>
      <div className="title-content">
        <h2>Patient Portal</h2>
        {!isOtp ? (
          <>
            <Typography
              className="patient_login_text"
              variant="body2"
              sx={{ mt: { md: -2 } }}
            >
              Use the email your practitioner has on <br />
              file to sign in. Not registerd yet?
            </Typography>
            <Link className="nav-link" to="/register">
              Sign up here
            </Link>
          </>
        ) : preference == "text" ? (
          "You should have received OTP on your registered mobile number."
        ) : (
          "You should have received OTP on your registered email account."
        )}
      </div>
      {!isOtp ? (
        <form onSubmit={handleSubmit(handleLogin, handleError)}>
          {isValid ? (
            <Alert className="page-alert" severity="error">
              Invalid email or password
            </Alert>
          ) : (
            ""
          )}
          <div className="form-section">
            <Stack spacing={3}>
              <TextField
                type="text"
                fullWidth
                name="email"
                label="Email"
                {...register("email", loginOptions.email)}
              />
              <small className="text-danger">
                {errors?.email && errors.email.message}
              </small>
              <TextField
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                label="Password"
                {...register("password", loginOptions.password)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Icon
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <small className="text-danger">
                {errors?.password && errors.password.message}
              </small>

              <LoadingButton
                className="page-button"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                SIGN IN
              </LoadingButton>
              <Link className="nav-link" to="/forgot-password">
                Forgot Password
              </Link>
              <Link className="nav-link" to="/doctor/signin">
                Provider Login
              </Link>
            </Stack>
          </div>
        </form>
      ) : (
        <OtpVerification
          Token={token}
          Hash={hash}
          Phone={phone}
          Id={id}
          Preference={preference}
        />
      )}
    </>
  );
}
