import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import { useNavigate, Link } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Icon } from '@iconify/react';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Stack, Checkbox, TextField, FormControlLabel, RadioGroup, Radio, InputAdornment, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Privacy from "../../../pages/Privacy";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
var moment = require('moment');

export default function RegisterForm() {
  const navigation = useNavigate()
  useEffect(()=>{
    var Token = localStorage.getItem("token");
    if(Token){
      navigation("/dashboard")
    }
  },[])  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = useState();
  const [isRegister, setIsRegister] = useState(false);
  const [value, setValue] = useState(null);
  const [emailExist, setEmailExist] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const handleRegistration = async(formdata) => {
    formdata.phone = phone
    const Result = await fetch(process.env.REACT_APP_NODE_API_URL+'/api/CheckEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: formdata.email}),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.email) {
          window.scrollTo(0, 300)
          setEmailExist(data.email)
        } else {
          setEmailExist(false)
          formdata.birthdate=moment(formdata.birthdate).format('YYYY-MM-DD')
          window.scrollTo(0, 0)
          setFormData(formdata);
          setIsRegister(true);
        }
        return data;
      });
  };
  const handleError = (errors) => {
    if(phone == ''){
      setPhoneError(true)
    }else{
      setPhoneError(false)
    }
  };
  function blankError(date){
    setValue(date)
    reset({birthdate:date},{
      keepErrors: true, 
      keepDirty: true,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false,
    })
  }


  const registerOptions = {
    name: { required: 'FullName is required' },
    email: {
      required: 'Email is required',
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter a valid email',
      },
    },
    phone: { required: 'Phone Number is required' },
    birthdate: { required: 'Birthdate is required' },
    notification: { required: 'Communication Preference is required'},
    password: {
      required: 'Password is required',
      minLength: {
        value: 8,
        message: 'Password must have at least 8 characters',
      },
    },
  };



  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '5px',
    width: 28,
    height: 25,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px #31abf7'
        : 'inset 0 0 0 1px #31abf7, inset 0 -1px 0 #31abf7',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 25,
      height: 25,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      position: 'absolute',
      left: "10px",
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  function BpRadio(props: RadioProps) {
    return (
      <Radio
        sx={{
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  function handleChange() {
    window.scrollTo(0, 0)
    setIsRegister(false);
  }

  return (
    <>
    {!isRegister ? (
      <>
       <div className='title-content'>
            {/* <Typography variant="h2" gutterBottom> */}
              <h2>Register</h2>
            {/* </Typography> */}
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
      Use the email your practitioner has on <br />
      file to sign up for video follow ups. <br />Already registerd? {''}
              <Link variant="subtitle2" to="/">
                Sign in here.
              </Link>
    </Typography>
            </div>
    <form onSubmit={handleSubmit(handleRegistration, handleError)}>
      <div className='form-section' >
      <Stack spacing={3}>
        <TextField type="text" fullWidth name="name" label="Full Name" {...register('name', registerOptions.name)} />
        <small className="text-danger">{errors?.name && errors.name.message}</small>
        <TextField type="text" fullWidth name="email" label="Email" {...register('email', registerOptions.email)} />
        {emailExist ? (
        <small className="text-danger">Email Already Exist!</small>
        ) : (<small className="text-danger">{errors?.email && errors.email.message}</small>)}
        
        {/* <TextField
          type="number"
          fullWidth
          name="phone"
          label="Phone Number"
          {...register('phone', registerOptions.phone)}
        /> */}

    <ReactPhoneInput
        // value={value}
        label="Phone Number"
        onChange={(e)=>setPhone(e)}
        inputProps={{
          name: 'phone',
          autoFocus: true
        }}
        component={TextField}
        renderInput={(params) => <TextField {...params} {...register('phone', registerOptions.phone)}/>}
      />
        
        <small className="text-danger">{phoneError ? ('Phone Number is required') : ('')}</small>
        {/* <small className="text-danger">{errors?.phone && errors.phone.message}</small> */}
        <TextField
          type={showPassword ? 'text' : 'password'}
          fullWidth
          name="password"
          label="Password"
          {...register('password', registerOptions.password)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Icon icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <small className="text-danger">{errors?.password && errors.password.message}</small>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label="Birth Date"
        name='birthdate'
        value={value}
        inputFormat="dd/MM/yyyy"
        maxDate={new Date()}
        onChange={(newValue) => {
          blankError(newValue);
        }}
        renderInput={(params) => <TextField {...params} {...register('birthdate', registerOptions.birthdate)}/>}
      />
    </LocalizationProvider>
        {/* <TextField
          type="date"
          fullWidth
          name="birthdate"
          label="Birth Date"
          {...register('birthdate', registerOptions.birthdate)}
        /> */}
        <small className="text-danger">{errors?.birthdate && errors.birthdate.message}</small>
        <div className='radio-tag'>
          <p>Communication Preference</p>
        <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" defaultValue={formData != undefined ? formData.notification : ''}>
          <FormControlLabel
            value="email"
            {...register('notification', registerOptions.notification)}
            control={<BpRadio />}
            label="Email"
          />
          <FormControlLabel
            value="text"
            {...register('notification', registerOptions.notification)}
            control={<BpRadio />}
            label="Text"
          />
          <FormControlLabel
            value="both"
            {...register('notification', registerOptions.notification)}
            control={<BpRadio />}
            label="Both"
          />
        </RadioGroup>
        </div>
        <small className="text-danger">{errors?.notification && errors.notification.message}</small>
        <LoadingButton className='page-button' fullWidth size="large" type="submit" variant="contained">
          NEXT
        </LoadingButton>
      </Stack>
      </div>
    </form>
    </>
    ) : (
      <Privacy formData={formData} onChange={handleChange} />
      )}
      </>
  );
}
