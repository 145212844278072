// routes
import React, { useState, useEffect } from "react";
import "./App.css";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Header from "./components/Section/Header";
import Footer from "./components/Section/Footer";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const timeout = 300000;
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => setIsIdle(true);

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  });

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();

  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
    }, 60000);
  }, []);
  useEffect(()=>{
    if (isIdle == true && localStorage.getItem("token") != null) {
      setIsIdle(false)
      localStorage.removeItem("token");
      navigate("/");
    }else{
      setIsIdle(false)
    }
  },[isIdle])
 
  return (
    <ThemeProvider>
      <Header />
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <Footer />
    </ThemeProvider>
  );
}
