import { React, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
// @mui
import { Icon } from "@iconify/react";
import jwt_decode from 'jwt-decode';

import {
  Stack,
  TextField,
  Alert,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
const OtpVerification = ({Token,Hash,Phone,Id,Preference}) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isValidOtp, setIsValidOtp] = useState(false);

  const handleVerification = async (data) => {
    data.hash = Hash
    data.authKey = Phone
    data.id = Id
    await fetch(process.env.REACT_APP_NODE_API_URL+'/api/verifyOtp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.otp){
          localStorage.setItem('token', Token);
          var decoded = jwt_decode(Token);
          if (decoded.CreateUserToken.role_id == 3) {
            navigate('/dashboard');
          } 
        }else{
          setIsValidOtp(true)
        }
      });
  };
  const handleError = (errors) => {};

  const verificationOptions = {
    otp: {
      required: "Please enter OTP",
    },
  };

  return (
      <div className='form-section'>
    <form onSubmit={handleSubmit(handleVerification, handleError)}>
    {isValidOtp ? (
        <Alert className='page-alert' severity="error">
          Invalid OTP
        </Alert>
      ) : (
        ''
      )}
      <Stack spacing={3}>
        <TextField
          type="text"
          fullWidth
          name="otp"
          label="OTP"
          {...register("otp", verificationOptions.otp)}
        />
        <small className="text-danger">
          {errors?.otp && errors.otp.message}
        </small>
        <LoadingButton className='page-button' fullWidth size="large" type="submit" variant="contained">
          Verify
        </LoadingButton>
      </Stack>
    </form>
    </div>
  );
};

export default OtpVerification;
