import { React, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";

// @mui
import { Icon } from "@iconify/react";
import {
  Stack,
  TextField,
  Alert,
  InputAdornment,
  IconButton,
  Container,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import jwt_decode from "jwt-decode";
import Page from "../../components/Page";
import useResponsive from "../../hooks/useResponsive";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function LoginForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const smUp = useResponsive("up", "sm");

  useEffect(() => {
    var Token = localStorage.getItem("token");
    if (Token) {
      navigate("/dashboard");
    }
  }, []);

  const handleRegistration = async (data) => {
    await fetch(process.env.REACT_APP_NODE_API_URL + "/api/DoctorLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.message) {
          localStorage.setItem("token", data.Token);
          var decoded = jwt_decode(data.Token);
          if (decoded.CreateUserToken.role_id == 3) {
            navigate("/dashboard");
          } else {
            navigate("/dashboard");
          }
        } else {
          setIsValid(true);
        }
        return data;
      });
  };
  const handleError = (errors) => {};

  const loginOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Please enter a valid email",
      },
    },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
  };

  return (
    <Page title="Doctor Login">
      <RootStyle>
        <form onSubmit={handleSubmit(handleRegistration, handleError)}>
          <Container className="page-box" maxWidth="sm">
            <ContentStyle>
              {smUp && (
                <div className="title-content">
                  {/* <Typography variant="body2" sx={{ mt: { md: -2 } }}> */}
                  <h2>Doctor Portal {""}</h2>
                  {/* </Typography> */}
                </div>
              )}
              <div className="form-section">
                <Stack spacing={3}>
                  <TextField
                    type="text"
                    fullWidth
                    name="email"
                    label="Email"
                    {...register("email", loginOptions.email)}
                  />
                  <small className="text-danger">
                    {errors?.email && errors.email.message}
                  </small>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    name="password"
                    label="Password"
                    {...register("password", loginOptions.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            <Icon
                              icon={
                                showPassword
                                  ? "eva:eye-fill"
                                  : "eva:eye-off-fill"
                              }
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <small className="text-danger">
                    {errors?.password && errors.password.message}
                  </small>
                  {isValid ? (
                    <Alert className="page-alert" severity="error">
                      Invalid email or password
                    </Alert>
                  ) : (
                    ""
                  )}
                  <LoadingButton
                    className="page-button"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    SIGN IN
                  </LoadingButton>
                  <Link className="nav-link" to="/doctor/forgot-password">
                    Forgot Password
                  </Link>
                  <Link className="nav-link" to="/">
                    Click here for patient sign in
                  </Link>
                </Stack>
              </div>
            </ContentStyle>
          </Container>
        </form>
      </RootStyle>
    </Page>
  );
}
