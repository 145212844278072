import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';
import { RadioGroup, FormControlLabel, Stack, Alert, Grid, Button, TextareaAutosize, Radio  } from '@mui/material';
import jwt_decode from 'jwt-decode';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrivacyContent from './PrivacyContent';
const Privacy = (props) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(props.formData);
  const [isPrivacy, setIsPrivacy] = useState('');
  const [isExist, setIsExist] = useState(false);
  const [message, setMessage] = useState('');

  async function Register() {
    let decoded;
    const Result = await fetch(process.env.REACT_APP_NODE_API_URL+'/api/SignUp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ post: userData }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.isRegister == false) {
          setMessage(data.message);
          setIsExist(true);
        } else {
          localStorage.setItem('token', data.Token);
          decoded = jwt_decode(data.Token);
          if (decoded.CreateUserToken.role_id == 3) {
            navigate('/dashboard');
          }
        }
        return data;
      });
  }

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '5px',
    width: 25,
    height: 25,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px #31abf7'
        : 'inset 0 0 0 1px #31abf7, inset 0 -1px 0 #31abf7',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 25,
      height: 25,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });

  function BpRadio(props: RadioProps) {
    return (
      <Radio
        sx={{
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }

  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange();
}

// console.log(privacyContent)
// console.log( formatter.render(strippedHtml) );
  return (
    <div>
      <Grid container>
        <div className="privacy-box title-content form-section">
          <div className="tital">
            <h2>Privacy</h2>
            <p>Please review your medical privacy
            <br /> below and accept to finish <br />settings up your account.</p>
          </div>
        <div className='page-textarea'>
 
    <PrivacyContent />
   
          {/* <TextareaAutosize
            maxRows={20}
            aria-label="maximum height"
            placeholder="Maximum 4 rows"
            defaultValue={privacyContent}
            style={{ width: 200 }}
          /> */}
          </div>
          <div className='accept-text-titl'>
          <p>Do You accept these terms?</p>
          </div>
          
          <RadioGroup className='radio-button' row aria-labelledby="demo-radio-buttons-group-label" defaultValue="">
            <FormControlLabel value="Agree" control={<BpRadio />} label="Agree" onClick={() => setIsPrivacy(true)} />
            <FormControlLabel
              value="Disagree"
              control={<BpRadio />}
              label="Disagree"
              onClick={() => setIsPrivacy(false)}
            />
          </RadioGroup>
          <br />
          <div className='button-back-regi'>
          <Button className='privacy-button' onClick={handleChange} variant="contained">BACK</Button>
          {isPrivacy === true ? <Button  onClick={() => Register()} className='privacy-button' variant="contained">
          SIGN UP </Button>
         : <Button className='privacy-button-disabled' disabled variant="contained">SIGN UP</Button>}
         </div>
        </div>
      </Grid>
    </div>
  );
};

export default Privacy;
