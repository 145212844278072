import { React, useEffect, useState } from "react";
import {
  Card,
  Table,
  Button,
  CardContent,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Breadcrumbs,
  Dialog,
  DialogContent,
  Skeleton,
  Container,
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import { useNavigate, Link } from "react-router-dom";
var moment = require("moment");

const PatientDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    GetPatientVideos();
  }, []);
  const [patientVideos, setPatientVideos] = useState();
  const [usersData, setUsersData] = useState();

  async function GetPatientVideos() {
    await fetch(
      process.env.REACT_APP_NODE_API_URL + "/api/getVideosByPatientId",
      {
        method: "GET",
        headers: {
          "X-access-token": `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.auth) {
          setUsersData(data.PatientVideo[0]);
          setPatientVideos(data.PatientVideo);
        } else {
          localStorage.removeItem("token");
          navigate("/");
        }
        return data;
      });
  }

  var UserVideo = [];

  if (patientVideos != undefined) {
    UserVideo = patientVideos.map((video) => {
      const SentDateFormat = moment(video.vc_sent_date).format("MMM DD, YYYY");
      return (
        <>
          {video.vc_video != null ? (
            <>
              <TableRow
                className="patient-row"
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="patient-td" align="left">
                  <div className="patient-iocn-vname">
                    <PlayCircleIcon />
                    <Link
                      to={"/video/id/" + video.vc_id}
                      state={{ user_id: video.user_id }}
                    >
                      {video.vc_title}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className="patient-td" align="right">
                  {SentDateFormat}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <div className="no-video">You have no videos</div>
          )}
        </>
      );
    });
  }

  function ViewVideo(id, user_id) {
    navigate("/video/id/" + id, { state: { user_id: user_id } });
  }

  return (
    <div>
      <Container className="patient_video_top" maxWidth="lg">
        {patientVideos != undefined ? (
          <TableContainer component={Paper}>
            <Table
              className="patient-table"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              {usersData != undefined && usersData.vc_video != null ? (
                <TableHead>
                  <TableRow>
                    <TableCell className="row-title-video" align="left">
                      Videos
                    </TableCell>
                    <TableCell className="row-title-date" align="left">
                      DATE SENT
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                ""
              )}
              <TableBody>{UserVideo}</TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Skeleton
            className="patient-tb-skeleton"
            variant="rounded"
            height={500}
          />
        )}
      </Container>
    </div>
  );
};

export default PatientDashboard;
