import {React,useEffect,useState} from 'react';
import Logo from '../../logo.png'
import { Link, useLocation,useNavigate} from "react-router-dom";
import AccountPopover from '../../layouts/dashboard/AccountPopover';
import jwt_decode from 'jwt-decode';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState();

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

    useEffect(()=>{
        if(localStorage.getItem('token') != undefined){
        const UserData = jwt_decode(localStorage.getItem('token'));
        if(UserData.CreateUserToken.role_id == 3){
            GetPatientName(UserData.CreateUserToken.id);
          }else{
            setUserProfile(undefined)
          }
        }
      },[location])
      async function GetPatientName(Id){
        await fetch(process.env.REACT_APP_NODE_API_URL + "/api/getUserProfile", {
            method: "GET",
            headers: {
              "X-access-token": `Bearer ${localStorage.getItem("token")}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.auth) {
                // console.log(data.UserProfile)
                setUserProfile(data.UserProfile)
              } else {
                localStorage.removeItem("token");
                navigate("/");
              }
              return data;
            });
      }
    return (
        <>
        <div className={localStorage.getItem('token') != undefined ? "small-header" : "normal-header"}>
            <div className='header-section' id='scroll-to-privacy'>
                <div className='logout-icon'>
                {location.pathname != '/' && location.pathname != '/register' && location.pathname != '/doctor/signin' && location.pathname != '/doctor/forgot-password' && location.pathname != '/forgot-password' && location.pathname != '/reset-password' && location.pathname != '/doctor/reset-password' ? (
                    <>
                <AccountPopover />
                    </>
                    
                ) : ('')}
                </div>
                <div className='logo container'>
                    <Link to='/'><img src={Logo} /></Link>
                </div>  
                <div className='user-name'>
                    {userProfile != undefined && userProfile.role_id == 3 ? (
                        <h2>Welcome Back, {userProfile != undefined ? (capitalizeFirst(userProfile.name+".")) : ('')}</h2>
                    ) : ('')}
                    </div>          
            </div>
        </div>
        </>
    );
};

export default Header;