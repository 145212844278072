import { React, useState, useEffect } from "react";
import DoctorDashboard from "../components/Doctor/DoctorDashboard";
import PatientDashboard from "../components/Patient/PatientDashboard";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");

  useEffect(() => {
    var Token = localStorage.getItem("token");
    if (!Token) {
      navigate("/");
    } else {
      var decoded = jwt_decode(localStorage.getItem("token"));
      setRole(decoded.CreateUserToken.role_id);
    }
  }, []);

  return <div>{role == 2 ? <DoctorDashboard /> : <PatientDashboard />}</div>;
};

export default Dashboard;
