import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui

import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Link } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [user, setUser] = useState();
  const [profilePic, setProfilePic] = useState();
  useEffect(()=>{
    if(localStorage.getItem('token') != undefined){
    const UserData = jwt_decode(localStorage.getItem('token'));
    if(UserData.CreateUserToken.role_id == 2){
      GetDoctorImage(UserData.CreateUserToken.id);
    }
    setUser(UserData.CreateUserToken)
    }
  },[])

  async function GetDoctorImage(Id){
    await fetch(process.env.REACT_APP_NODE_API_URL + "/api/getDoctorImage", {
      method: "GET",
      headers: {
        "X-access-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.auth) {
          setProfilePic(data.ProfilePic.profile_pic)
        } else {
          localStorage.removeItem("token");
          navigate("/");
        }
        return data;
      });
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  async function logout() {
    let tokenData = localStorage.getItem("token");
    localStorage.removeItem("token");
    if(user.role_id == 3){
    await fetch(process.env.REACT_APP_NODE_API_URL+'/api/patient_manage_log', {
      method: 'POST',
      headers: {
        "X-access-token": `Bearer ${tokenData}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.id, flag: 'logout', status: 1}),
    })
  }
  }
  let MENU_OPTIONS;
if(user != undefined && user.role_id == 3){
   MENU_OPTIONS = [
    {
      label: 'Dashboard',
      icon: 'eva:home-fill',
      linkTo: '/dashboard',
    },
    {
      label: 'Account Management',
      icon: 'eva:home-fill',
      linkTo: '/account-management',
    },
  ];
}else{
   MENU_OPTIONS = [
    {
      label: 'Dashboard',
      icon: 'eva:home-fill',
      linkTo: '/dashboard',
    },
  ];
}


  return (
    <>
   
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={profilePic != undefined && user.role_id == 2 ? (process.env.REACT_APP_NODE_API_URL+'/assets/ProfilePicture/'+profilePic) : (account.patientphotoURL)} alt="doctorphotoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user != undefined ? (user.name) : ('')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {user != undefined ? (user.email) : ('')}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => {
            return(
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
            )
          })}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Link onClick={() => logout() } variant="subtitle2" to="/" component={RouterLink}>
        <MenuItem onClick={handleClose} sx={{ m: 1 }}>
                  Logout
        </MenuItem>
                </Link>
          {/* Logout */}
      </MenuPopover>
    </>
  );
}
