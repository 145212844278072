import React from 'react';
const PrivacyContent = () => {

    return (
    <div className='privacy_content'>
 <h1>HIPAA Compliance MN</h1>
    <a href="https://www.atlantic.net/hipaa-compliant-hosting/" target="_blank">https://www.atlantic.net/hipaa-compliant-hosting/</a>
    <h2>HIPAA </h2>
    <p>This notice describes how medical information about you may be used and disclosed and how you can get access to this information. This document is adapted from U.S. Department of Health and Human Services Model Notice of Privacy Practices. Minnesota’s legal requirements are in italic text. Please review it carefully and ask any questions you may have prior to signing. </p>
    <p><b>YOUR RIGHTs</b></p>
    <p><b>When it comes to your health information, you have certain rights. </b></p>
    <p>This section explains your rights and some of my responsibilities to help you. </p>
    <p><b>Receive an electronic or paper copy of your medical record</b></p>
    <ul>
      <li>You can ask to see or copy an electronic or paper copy of your medical record and other health information we have about you. Ask me how to do this.</li>
      <li>I will provide a copy or a summary of your health information within a reasonable time.</li>
      <li>If you ask to see or receive a copy of your record for purposes of reviewing current medical care, I may not charge you a fee.   [Minn. Stat. § 144.292 subd. 6]</li>
      <li>If you request copies of your patient records of past medical care, or for certain appeals, I may charge you specified fees. [Minn. Stat. § 144.292 subd. 6]</li>
    </ul>
    <p><b>Ask me to correct your medical record </b></p>
    <ul>
      <li>You can ask me to correct health information about you that you think is incorrect or incomplete. Ask me how to do this.</li>
      <li>I may say “no” to your request, but I’ll tell you why in writing within 60 days.</li>
    </ul>
    <p><b>Request for me to contact you confidentially </b></p>
    <ul>
      <li>You can ask me to contact you in a specific way (for example, home or office phone) or to send mail to a different address.</li>
      <li>I will say “yes” to all reasonable requests.</li>
    </ul>
    <p><b>Ask me to limit what I use or share</b></p>
    <ul>
      <li>You can ask me not to use or share certain health information for treatment, payment, or my operations (TPO). I am not required to agree to your request, and I may say “no” if it would affect your care. </li>
      <li>If you pay for a service or health care item out-of-pocket in full, you can ask me not to share that information for the purpose of payment or my operations with your health insurer. I will say “yes” unless a law requires me to share that information. Minnesota Law requires consent for disclosure of treatment, payment, or operations information.   [Minn. Stat. § 144.293 subd. 2] </li>
    </ul>
    <p><b>Get a list of those with whom I’ve shared information </b></p>
    <ul>
      <li>You can ask for a list (accounting) of the times I’ve shared your health information for six years prior to the date you ask, who I shared it with, and why. </li>
      <li>I will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked me to make).</li>
      <li>I’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within that time. </li>
    </ul>
    <p><b>Get a copy of this privacy notice </b></p>
    <p>You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. I will provide you with a paper copy promptly. You can also access a copy on my website at docdavis.com </p>
    <p><b>File a complaint if you feel your rights are violated </b></p>
    <ul>
      <li>You can complain if you feel I have violated your rights by contacting me using the information on this document. </li>
      <li>You can file a complaint with the U.S. Department of Health and Human Services Office for Civil Rights by sending a letter to 200 Independence Avenue, S.W., Washington, D.C. 20201, calling 1-877-696-6775, or visiting <a href="https://www.hhs.gov/hipaa/filing-a-complaint/what-to-expect/index.html" target="_blank">www.hhs.gov/ocr/privacy/hipaa/complaints/. </a></li>
      <li>I will not retaliate against you for filing a complaint.</li>
    </ul>
    <p><b>YOUR CHOICES</b></p>
    <p><b>For certain health information, you can tell me your choices about what I share.</b></p>
    <p>If you have a clear preference for how I share your information in the situations described below, talk to me. Tell me what you want me to do, and I will follow your instructions. In these cases, you have both the right and choice to tell me NOT to: </p>
    <ul>
      <li>Share information with your family, close friends, or others involved in your care </li>
      <li>Share information in a disaster relief situation</li>
      <li>Include your information in a hospital directory</li>
    </ul>
    <p>If you are not able to tell me your preference, for example if you are unconscious, I may go ahead and share your information if I believe it is in your best interest. I may also share your information when needed to lessen a serious and imminent threat to health or safety.</p>
    <p><b>In these cases, I will never share your information unless you give me written permission: </b></p>
    <ul>
      <li>Marketing purposes</li>
      <li>Sale of your information</li>
      <li>Most sharing of psychotherapy notes</li>
    </ul>
    <p>Minnesota Law also requires consent for most other sharing purposes. Note: I do not use your information for marketing, nor do I sell your information.</p>
    <p><b>MY USES AND DISCLOSURES</b></p>
    <p><b>How do I typically use or share your health information? </b></p>
    <p>I typically use or share your health information in the following ways: I need your consent before I disclose protected health information for treatment, payment, and operations purposes, unless the disclosure is to a related entity, or the disclosure is for a medical emergency and I am unable to obtain your consent due to your condition or the nature of the medical emergency.   [Minn. Stat. § 144.293, subd. 2 and 5]</p>
    <p><b>Treat you</b></p>
    <p>I can use your health information and share it with other professionals who are treating you only if I have your consent. I can only release your health records to health care facilities and providers outside of my network without your consent if it is an emergency and you are unable to provide consent due to the nature of the emergency. I may also share your health information with a provider in my network.   [Minn. Stat. § 144.293, subd. 2 and 5]Example: A doctor treating you for an injury asks another doctor about your overall health condition.</p>
    <p><b>Run my organization</b></p>
    <p>I can use and share your health information to run my practice, improve your care, and contact you when necessary. I am required to obtain your consent before I release your health records to other providers for their own health care operations.   [Minn. Stat. § 144.293, subd. 2 and 5] Example: I use health information about you to manage your treatment and services.</p>
    <p><b>Bill for your services</b></p>
    <p>I can use and share your health information to bill and get payment from health plans or other entities only if I obtain your consent.   [Minn. Stat. § 144.293, subd. 2 and 5].Example: I give information about you to your health insurance plan so it will pay for your services.</p>
  
    <p><b>How else can I use or share your health information?</b></p>
    <p>I am allowed or required to share your information in other ways, usually in ways that contribute to the public good, such as public health and research. I have to meet many conditions in the law before I can share your information for these purposes. For more information see: </p>
    <a href="https://www.hhs.gov/hipaa/for-individuals/guidance-materials-for-consumers/index.html" target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/index.html.</a>
    
    <p><b>Help with public health and safety issues</b></p>
    <p>I can share health information about you for certain situations such as:</p>
    <ul>
      <li>Preventing disease</li>
      <li>Helping with product recalls</li>
      <li>Reporting adverse reactions to medications</li>
      <li>Reporting suspected abuse, neglect, or domestic violence</li>
      <li>Preventing or reducing a serious threat to anyone’s health or safety</li>
    </ul>
  
    <p><b>Do research</b></p>
    <p>Dr. Rick Davis does not currently engage in research activities. However, I can use or share your information for health research if you do not object.   [Minn. Stat. § 144.295 subd. 1]</p>
  
    <p><b>Comply with the law</b></p>
    <p>I will share information about you if state or federal laws require it, including with the Department of Health and Human Services if it wants to see that I’m complying with federal privacy law.  [Minn. Stat. § 144.293 subd. 2]</p>
  
    <p><b>Respond to organ and tissue donation requests</b></p>
    <p>I can share health information about you with organ procurement organizations only with your consent.  [Minn. Stat. § 525A.14]</p>
  
    <p><b>Work with a medical examiner or coroner</b></p>
    <p>I can share health information with a coroner and medical examiner when an individual dies. I need consent to share information with a funeral director.   [Minn. Stat. § 390.11 subd. 7 (a)]</p>
  
    <p><b>Address workers’ compensation, law enforcement, and other government requests </b></p>
    <p>I can use or share health information about you: </p>
    <ul>
      <li>For workers’ compensation claims</li>
      <li>For law enforcement purposes or with a law enforcement official with your consent, unless required by law.   [Minn. Stat. § 144.293, subd. 2]</li>
      <li>With health oversight agencies for activities authorized by law </li>
      <li>For special government functions such as military, national security, and presidential protective services with your consent, unless required by law.   [Minn. Stat. § 144.293, subd. 2] </li>
    </ul>
  
    <p><b>Respond to lawsuits and legal actions</b></p>
    <p>I can share health information about you in response to a court or administrative order, or in response to a subpoena. </p>
  
    <p><b>Other State Law</b></p>
    <p>In Minnesota, I need your consent before I disclose protected health information for treatment, payment, and operations purposes, unless the disclosure is to a related entity, or the disclosure is for a medical emergency and I am unable to obtain your consent.  [Minn. Stat. §§ 13.386, 254A.09] </p>
  
    <p><b>MY RESPONSIBILITIES </b></p>
    <p>I am required by law to maintain the privacy and security of your protected health information. I will let you know promptly if a breach occurs that may have compromised the privacy or security of your information. I must follow the duties and privacy practices described in this notice and give you a copy of it. I will not use or share your information other than as described here unless you tell me I can in writing. If you tell me I can, you may change your mind at any time. Let me know in writing if you change your mind. For more information see:</p>
    <a href="https://www.hhs.gov/hipaa/for-individuals/notice-privacy-practices/index.html" target="_blank">www.hhs.gov/ocr/privacy/hipaa/understanding/consumers/noticepp.html.</a>
  
    <p><b>CHANGES TO THE TERMS OF THIS NOTICE</b></p>
    <p>I can change the terms of this notice, and the changes will apply to all information I have about you. The new notice will be available upon request, in my office, and on my web site.</p>
  
    <p><b>OTHER INFORMATION</b></p>
    <p>Effective Date: 1/1/18</p>
    <p>Privacy Official: Rick A. Davis, MD</p>
    <p>Rick A. Davis, MD</p>
    <p>111 Hundertmark Rd Suite 240, Chaska, MN 55318</p>
    <p>Docdavis.com 952-442-2163 </p>
    <p>This notice only applies to Rick A. Davis, MD.</p>
  
    <p><b>STATEMENT OF UNDERSTANDING</b></p>
    <p>I hereby acknowledge that I have read in full the Minnesota Notice of Privacy Practices (HIPAA). I understand I may view this policy at anytime on docdavis.com, by signing into the Client Portal, or by requesting a copy.</p>
        </div>
    );


};

export default PrivacyContent;