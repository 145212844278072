import { useEffect, useRef, useState } from "react";
import {
  LinearProgress,
  Box,
  Button,
  TextField,
  TextareaAutosize,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { useReactMediaRecorder } from "react-media-recorder";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import MotionPhotosOffIcon from "@mui/icons-material/MotionPhotosOff";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { styled } from "@mui/material/styles";

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video width={500} ref={videoRef} playsInline autoPlay controls />;
};

export default function VideoUpload(props) {
  const [video, setVideo] = useState(null);
  const [thumbnail, setThumbnail] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [isStop, setIsStop] = useState(false);
  const [isComplete,setIsComplete] = useState(false);
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
    mediaRecorder,
  } = useReactMediaRecorder({ video: true });
  const handleStartRecording = () => {
    setVideo(null);
    startRecording();
    setIsRecording(true);
  };
  const handleStopRecording = async () => {
    setIsDone(false);
    setIsStop(true)
    stopRecording();
    // setVideoData();

    // const videoBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    // const videoFile = new File([videoBlob], "Doctorvideo.mp4", {
    //   type: "video/mp4",
    // });
    // setVideo(videoFile);
//     console.log("videoFile")
//     console.log(videoFile)
//     console.log("videoFile")
//     const thumbnail = await generateVideoThumbnail(videoFile);
// console.log(thumbnail)
//     var block = thumbnail.split(";");
//     // Get the content type of the image
//     var contentType = block[0].split(":")[1]; // In this case "image/gif"
//     // get the real base64 content of the file
//     var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
//     // Convert it to a blob to upload
//     var blob = b64toBlob(realData, contentType);
//     setThumbnail(blob);
  };
  useEffect(() => {
    if (mediaBlobUrl != undefined && isComplete != true) {
      setVideoData();
    }
  }, [status == 'stopped']);

  // useEffect(() => {
  //   console.log("stopRecording")
  //   console.log(mediaBlobUrl)
  //   console.log("stopRecording")
  //   if (mediaBlobUrl != undefined && status == "stopped") {
  //     setVideoData();
  //   }
  // }, [stopRecording]);

  async function setVideoData() {
    const videoBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
    const videoFile = new File([videoBlob], "Doctorvideo.mp4", {
      type: "video/mp4",
    });
    // console.log(video)
    // checkfunction();
    setVideo(videoFile);
    // setPriviewVideo(mediaBlobUrl);
    const thumbnail = await generateVideoThumbnail(videoFile);

    var block = thumbnail.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1]; // In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    var blob = b64toBlob(realData, contentType);
    setThumbnail(blob);
    setIsComplete(true)
  }
  const handleCloseRecording = () => {
    setIsRecording(false);
    setIsDone(false);
    stopRecording();
    props.function(false);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      // var video = document.querySelector("#recorded");
      const video = document.createElement("video");
      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  function handleClose(flag) {
    // setVideoData()
    stopRecording();
    setIsRecording(false);
    setIsDone(flag);
    return;
  }

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm();

  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [progress, setProgress] = useState(0);
  const [isDisabled, setIsDisabled] = useState();
  const [isLineBar, setIsLineBar] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [isVideoSize, setIsVideoSize] = useState(false);
  const handleFileChange = async () => {
    if (video.size > 524288000) {
      setIsVideoSize(true);
      return false;
    }

    const VideoData = {
      videoTitle: videoTitle,
      videoDescription: videoDescription,
    };
    if (!video) {
      setVideoError(true);
      return false;
    }
    setIsLineBar(true);
    const formData = new FormData();
    setIsDisabled("disabled");
    setProgress(10);

    formData.append("video", video);
    formData.append("image", thumbnail);
    axios
      .post(process.env.REACT_APP_NODE_API_URL + "/api/uploadVideo", formData, {
        headers: {
          "X-access-token": `Bearer ${localStorage.getItem("token")}`,
          patient_id: props.patienId,
          videoData: JSON.stringify(VideoData),
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.code == 200) {
          props.function(false);
        }
        return response;
      });
  };

  const handleError = (errors) => {};
  const FileOption = {
    file: { required: "This field is required" },
    title: { required: "This field is required" },
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = (props) => {
    const { children, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
  };

  function blankVideo() {
    setIsRecording(true);
    setVideo(null);
    setIsComplete(false)
    clearBlobUrl()
  }

  function RemoveVideo() {
    setVideo(null);
  }

  return (
    <div className="VideoInput">
      <form onSubmit={handleSubmit(handleFileChange, handleError)}>
        {/* <VideoCameraFrontIcon
          titleAccess="Record Video (max size 500MB)"
          onClick={() => blankVideo()}
        ></VideoCameraFrontIcon>
        {isDone && video != null ? (
          <>
            <a href={mediaBlobUrl} target="_blank">
              <p>Video Attached.</p>
            </a>
          </>
        ) : (
          ''
        )} */}
        <CloseIcon
                className="close-icon-video"
                onClick={() => handleCloseRecording()}
              ></CloseIcon>
        
              <div className="recording-design">
                <div className="video-preview">
                  {status == "recording" && (
                    <VideoPreview stream={previewStream} />
                  )}
                  {status != "recording" && (
                    <video
                      width={500}
                      id="recorded"
                      src={mediaBlobUrl}
                      controls
                    />
                  )}
                </div>
                <div className="status-recording">
                  <div className="status-name">
                    {isStop ? ('') : (
                    status == "idle" || status == "stopped" ? (
                      <MotionPhotosOffIcon className="recording-status" />
                    ) : (
                      <RadioButtonCheckedIcon className="recording-on-going" />
                    )
                    )}
                    {/* <p>{status}</p> */}
                  </div>
                  <div className="recording-status-svg">
                    {status != "recording" && video == null ? (
                      <PlayCircleIcon
                        titleAccess="start recording"
                        onClick={() => handleStartRecording()}
                        className="recording-start"
                      ></PlayCircleIcon>
                    ) : (
                      ""
                    )}
                    
                    {video == null && status == "recording" ? (
                      <StopCircleIcon
                        titleAccess="stop recording"
                        onClick={() => handleStopRecording()}
                        className="recording-stop"
                      ></StopCircleIcon>
                    ) : (
                      ""
                    )}
                    {/* {video != null ? (
                      <Button
                        className="done-button"
                        onClick={() => handleClose(true)}
                      >
                        Done
                      </Button>
                    ) : (
                      ""
                    )} */}
                    
                  </div>
                </div>
              </div>
          
      
        {/* <TextField className='choose-file' type='hidden' name='file' {...register('file', FileOption.file)} type="file" onChange={(e) => setVideos(e)} /> */}
        <p>
          <small className="text-danger">
            {videoError && "Please record video."}
          </small>
        </p>
        <p>
          <small className="text-danger">
            {isVideoSize && "Video size is exceed 500MB."}
          </small>
        </p>
{isStop ? (
  <>
        <TextField
          name="title"
          autoFocus
          {...register("title", FileOption.title)}
          className="video-upload-text"
          type="text"
          onChange={(e) => setVideoTitle(e.target.value)}
          placeholder="Video Title"
        />
        <small className="text-danger">
          {errors?.title && errors.title.message}
        </small>
        <TextareaAutosize
          className="video-upload-textarea"
          minRows={4}
          onChange={(e) => setVideoDescription(e.target.value)}
          placeholder="Below Video Copy"
          style={{ width: 200 }}
        />
        <Box sx={{}}>
          {isLineBar && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </Box>
        <Button
          className="video-uplode-button"
          disabled={isDisabled}
          type="submit"
        >
          SEND VIDEO
        </Button>
        </>
        ) : ('')}
      </form>
    </div>
  );
}
