import {React, useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import { Stack, TextField, Alert, Container, InputAdornment, IconButton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { useForm } from 'react-hook-form';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const ResetPassword = () => {
  const navigation = useNavigate();
  useEffect(()=>{
    var Token = localStorage.getItem("token");
    if(Token){
      navigation("/dashboard")
    }
  },[])
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      const [isAuth, setIsAuth] = useState(false);
      const [isReset, setIsReset] = useState(false);
      const [formData, setFormData] = useState();
      const [showPassword, setShowPassword] = useState(false);

      const handleResetPassword = async(data) => {
			setFormData(data);
        await fetch(process.env.REACT_APP_NODE_API_URL+'/api/resetPassword', {
            method: 'POST',
            headers: {
                "X-access-token": `Bearer ${token}`,
                "NewPassword": `${data.password}`
            },
            body: JSON.stringify(data),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.Auth) {
                reset(formData);
                setIsReset(true);
                setIsAuth(false);
                navigation('/')
              } else {
                setIsReset(false);
                setIsAuth(true);
              }
              return data;
            });
        
      };
      const handleError = (errors) => {};
      const ResetOption = {
        password: {
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          },
      };

    return (
        <div className='title-content'>
      <Page title="Forgot Password">
        <RootStyle>
          <Container className='page-box' maxWidth="sm">
            <ContentStyle>
            <form onSubmit={handleSubmit(handleResetPassword, handleError)}>
      <div className='form-section' >
      {isAuth ? (
        <Alert className='page-alert' severity="error">
          Authentication Failed
        </Alert>
      ) : (
        ''
      )}
       {isReset ? (
        <Alert severity="success">
          Password Reset Successfully
        </Alert>
      ) : (
        ''
      )}
      <Stack spacing={3}>
      <TextField
          type={showPassword ? 'text' : 'password'}
          fullWidth
          name="password"
          label="Password"
          {...register('password', ResetOption.password)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Icon icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <small className="text-danger">{errors?.password && errors.password.message}</small>
        <LoadingButton className='page-button' fullWidth size="large" type="submit" variant="contained">
          Reset
        </LoadingButton>
    <Link className="nav-link" to="/">Click here to Signin</Link>

      </Stack>
      </div>
    </form>

            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>

      </div>
    );
};

export default ResetPassword;