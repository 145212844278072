import { React, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
// form
import { styled } from "@mui/material/styles";

import { useForm } from "react-hook-form";
// @mui
import { Icon } from "@iconify/react";

import {
  Stack,
  TextField,
  Alert,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Box,
  Container,
} from "@mui/material";

import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { LoadingButton } from "@mui/lab";
import ReactPhoneInput from "react-phone-input-material-ui";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import ContactPhoneRoundedIcon from "@mui/icons-material/ContactPhoneRounded";
import CakeRoundedIcon from "@mui/icons-material/CakeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
// components
import jwt_decode from "jwt-decode";

const style = {};
const AccountManagement = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let pwd = watch("new_password");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [userData, setUserData] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsChecked(false);
  };
  useEffect(() => {
    if (localStorage.getItem("token") != undefined) {
      const UserData = jwt_decode(localStorage.getItem("token"));
      if (UserData.CreateUserToken.role_id == 2) {
        navigate("/");
      }
    } else {
      navigate("/");
    }
    GetUserProfile();
  }, []);

  async function GetUserProfile() {
    await fetch(process.env.REACT_APP_NODE_API_URL + "/api/getUserProfile", {
      method: "GET",
      headers: {
        "X-access-token": `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserData(data.UserProfile);
        return data;
      });
  }

  const handleProfile = async (data) => {
    setIsSuccess(false);
    await fetch(process.env.REACT_APP_NODE_API_URL + "/api/updateuserprofile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-access-token": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Auth) {
          setIsSuccess(true);
        } else {
          setIsValid(true);
        }
        if (data.Password) {
          localStorage.removeItem("token");
          navigate("/");
        }

        return data;
      });
  };
  const handleError = (errors) => {};

  const profileOption = {
    password: {
      required: "This is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    new_password: {
      required: "This is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    confirm_password: {
      required: "This is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
      validate: (value) => value === pwd || "The passwords do not match",
    },
  };

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "5px",
    width: 28,
    height: 25,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px #31abf7"
        : "inset 0 0 0 1px #31abf7, inset 0 -1px 0 #31abf7",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 25,
      height: 25,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
      position: "absolute",
      left: "10px",
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });

  function BpRadio(props: RadioProps) {
    return (
      <Radio
        sx={{
          "&:hover": {
            bgcolor: "transparent",
          },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  const ChangePassword = (event) => {
    setIsChecked(event.target.checked);
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + parseInt(phoneNumberString)).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  return (
    <div>
      <Container className="page-box-2" maxWidth="sm">
        <Box sx={{ ...style, width: 800 }}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
          >
            {isSuccess ? (
              <Alert
                onClose={() => {
                  setIsSuccess(false);
                }}
                className=""
                variant="filled"
                severity="success"
              >
                Profile Update successfully.
              </Alert>
            ) : (
              ""
            )}
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonRoundedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Name" secondary={userData.name} />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <MarkEmailReadRoundedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Email" secondary={userData.email} />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CakeRoundedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="BirthDate"
                secondary={userData.birthdate}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <ContactPhoneRoundedIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Phone"
                secondary={formatPhoneNumber(userData.phone)}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
          <form onSubmit={handleSubmit(handleProfile, handleError)}>
            <Stack spacing={3}>
              <div className="radio-tag">
                <p>Communication Preference</p>
                {userData ? (
                  <RadioGroup
                    name="radio-buttons-group"
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={userData != "" ? userData.notification : ""}
                  >
                    <FormControlLabel
                      value="email"
                      {...register("notification", profileOption.notification)}
                      control={<BpRadio />}
                      label="Email"
                    />
                    <FormControlLabel
                      value="text"
                      {...register("notification", profileOption.notification)}
                      control={<BpRadio />}
                      label="Text"
                    />
                    <FormControlLabel
                      value="both"
                      {...register("notification", profileOption.notification)}
                      control={<BpRadio />}
                      label="Both"
                    />
                  </RadioGroup>
                ) : (
                  ""
                )}
              </div>

              <div className="form-section">
                <div className="radio-button-line">
                  <Checkbox onChange={ChangePassword} />
                  <p>Change your current password</p>
                  <br />
                </div>
                {isChecked ? (
                  <>
                    <label>
                      Once Password change you will be redirect on login page.
                    </label>

                    <TextField
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      name="password"
                      label="Current Password"
                      {...register("password", profileOption.password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Icon
                                icon={
                                  showPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small className="text-danger">
                      {errors?.password && errors.password.message}
                    </small>

                    <TextField
                      type={showNewPassword ? "text" : "password"}
                      fullWidth
                      name="new_password"
                      label="New Password"
                      {...register("new_password", profileOption.new_password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                              edge="end"
                            >
                              <Icon
                                icon={
                                  showNewPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small className="text-danger">
                      {errors?.new_password && errors.new_password.message}
                    </small>
                    <TextField
                      type={showConfirmPassword ? "text" : "password"}
                      fullWidth
                      name="confirm_password"
                      label="Confirm Password"
                      {...register(
                        "confirm_password",
                        profileOption.confirm_password
                      )}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              <Icon
                                icon={
                                  showConfirmPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <small className="text-danger">
                      {errors?.confirm_password &&
                        errors.confirm_password.message}
                    </small>
                    {isValid ? (
                      <Alert className="page-alert" severity="error">
                        Current Password does not match!!!
                      </Alert>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <div>
                  <LoadingButton
                    className="page-button"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Update
                  </LoadingButton>
                </div>
              </div>
            </Stack>
          </form>
        </Box>
      </Container>
    </div>
  );
};

export default AccountManagement;
