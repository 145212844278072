import React from 'react';


const Footer = () => {
    return (
        <>
        <div className='footer-section'></div>
        </>
    );
};

export default Footer;