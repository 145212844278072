import { React, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
// import { Button, Card, Modal, Table, Breadcrumb } from "react-bootstrap";
import VideoUpload from "../../components/VideoUpload";
import jwt_decode from "jwt-decode";
import { styled } from "@mui/material/styles";
import {
  Card,
  Table,
  Button,
  CardContent,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
var moment = require("moment");
import Swal from "sweetalert2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Patient = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [usersData, setUsersData] = useState();
  const [usersVideo, setUsersVideo] = useState();
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setSrc("");
  };
  const handleBack = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    try {
      var decoded = jwt_decode(localStorage.getItem("token"));
      if (decoded.CreateUserToken.role_id == 3) {
        setIsAuthorized(false);
      }
    } catch {
      navigate("/");
    }
    GetUserDetailsAndVideos();
  }, [show]);

  async function GetUserDetailsAndVideos() {
    await fetch(
      process.env.REACT_APP_NODE_API_URL + "/api/GetUserDetailsAndVideos",
      {
        method: "GET",
        headers: {
          "X-access-token": `Bearer ${localStorage.getItem("token")}`,
          UserId: params.id,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.auth) {
          if (!data.PatientVideo) {
            setIsAuthorized(data.PatientVideo);
          } else {
            setUsersData(data.PatientVideo[0]);
            setUsersVideo(data.PatientVideo);
          }
        } else {
          localStorage.removeItem("token");
          navigate("/");
        }
        return data;
      });
  }

  var UserVideo = [];
  const backUrl = "/some/other/value";

  if (usersVideo != undefined) {
    UserVideo = usersVideo.map((video) => {
      const SentDateFormat = moment(video.vc_sent_date).format("MMM DD, YYYY");
      return (
        <>
          {video.vc_video != null ? (
            <>
              <TableRow
                className="patient-row"
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="patient-td" align="left">
                  <div className="patient-iocn-vname">
                    <PlayCircleIcon />
                    <Link
                      to={"/video/id/" + video.vc_id}
                      params={{ testvalue: "hello" }}
                      state={{ user_id: video.user_id }}
                    >
                      {video.vc_title}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className="patient-td" align="right">
                  {SentDateFormat}
                </TableCell>
                <TableCell className="patient-td" align="right">
                  <DeleteForeverSharpIcon
                    onClick={() => DeleteVideo(video.vc_id, video.vc_video)}
                  />
                </TableCell>
              </TableRow>
            </>
          ) : (
                <div className="no-video">No Video Found</div>
          )}
        </>
      );
    });
  }

  async function DeleteVideo(id, name) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await fetch(
          process.env.REACT_APP_NODE_API_URL + "/api/DeleteVideoById",
          {
            method: "POST",
            headers: {
              "X-access-token": `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ video_id: id, video_name: name }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.auth) {
              if (data.status == "OK") {
                GetUserDetailsAndVideos();
              }
            } else {
              localStorage.removeItem("token");
              navigate("/");
            }
            return data;
          });
        Swal.fire("Deleted!", "Video has been deleted.", "success");
      }
    });
  }
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + parseInt(phoneNumberString)).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  return (
    <>
      <Container maxWidth="md">
        {!isAuthorized ? (
          <div className="no-video">You Don't have access</div>
        ) : (
          <div className="patient-video-upload">
            {usersData ? (
              <Card className="patient-detail" sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography className="patient_name">
                    <b>{usersData.user_name}</b>
                  </Typography>
                  <Typography className="mb-2 text-muted">
                    {usersData.user_birthdate}
                  </Typography>
                  <Typography className="mb-2 text-muted">
                    {usersData.users_email}
                  </Typography>
                  <Typography className="mb-2 text-muted">
                    {formatPhoneNumber(usersData.user_phone)}
                  </Typography>
                </CardContent>
              </Card>
            ) : (
              <div className="skelton-card">
                <Skeleton variant="rounded" height={144} />
              </div>
            )}
            <TableContainer component={Paper}>
              <Table
                className="patient-table"
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                {usersData ? (
                  usersData.vc_video != null ? (
                    <TableHead>
                      <TableRow key={1}>
                        <TableCell className="row-title-video" align="left">
                          Videos You've Sent
                        </TableCell>
                        <TableCell className="row-title-date" align="left">
                          DATE SENT
                        </TableCell>
                        <TableCell className="row-title-action" align="center">
                          ACTION
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    ""
                  )
                ) : (
                  <Skeleton
                    className="patient-tb-skeleton"
                    variant="rounded"
                    height={500}
                  />
                )}
                <TableBody>{UserVideo}</TableBody>
              </Table>
            </TableContainer>
            <Button className="patient-back-button" onClick={handleBack}>
              BACK
            </Button>
            <Button className="video-uplode-button" onClick={handleShow}>
              UPLOAD VIDEO
            </Button>
            <BootstrapDialog
              // onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={show}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                // onClose={handleClose}
              ></BootstrapDialogTitle>
              <DialogContent className="upload_line_remove" dividers>
                {/* <Typography gutterBottom> */}
                  <VideoUpload
                    patienId={usersData != undefined ? usersData.user_id : ""}
                    function={setShow}
                  />
                {/* </Typography> */}
              </DialogContent>
            </BootstrapDialog>
          </div>
        )}
      </Container>
    </>
  );
};

export default Patient;
